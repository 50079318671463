var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pr-0 pl-0"},[_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.Offers.state.items,"items-per-page":20,"server-items-length":_vm.Offers.state.total,"options":_vm.Offers.state.pagination,"hide-default-footer":"","disable-pagination":""},on:{"update:options":function($event){return _vm.$set(_vm.Offers.state, "pagination", $event)},"paginate":function($event){return _vm.Offers.actions.applyOptions({ value: $event })}},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.thumbnail)?_c('v-img',{attrs:{"src":("" + (item.thumbnail)),"width":"32px"}}):_vm._e()],1)]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-regular transparent",attrs:{"router":"","to":{ name: 'Offers', params: { _offer: item._id } },"small":"","transparent":""},on:{"click":function($event){return _vm.copyToClipboard(("" + (item._id)))}}},[_c('v-icon',{staticClass:"copy"},[_vm._v(_vm._s(_vm.Icons.copyContent))]),_c('FormattedText',{staticClass:"transparent",attrs:{"value":item._id}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('FormattedText',{attrs:{"value":item.name}})]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"categories"},[_c('FormattedText',{attrs:{"value":_vm.categoriesCharge(item)}})],1)]}},{key:"item.payout",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold payoutChip",attrs:{"small":"","label":""}},[_vm._v(_vm._s(_vm._f("payoutField")(item.payout,item.currency)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",style:(_vm.colorStatus(item.status)),attrs:{"small":"","label":""}},[_vm._v(_vm._s(_vm.statusText(item.status)))])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 text-capitalize",staticStyle:{"border":"1px solid #2196f3 !important"},attrs:{"id":item._id,"depressed":"","small":"","label":"","color":"white lighten-1","textColor":"blue"},on:{"click":_vm.select}},[_vm._v("Details "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }