<template>
  <v-row class="pr-0 pl-0">
    <data-table
      :headers="headers"
      :items="Offers.state.items"
      :items-per-page="20"
      :server-items-length="Offers.state.total"
      :options.sync="Offers.state.pagination"
      @paginate="Offers.actions.applyOptions({ value: $event })"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.thumbnail="{ item }">
        <div class="d-flex align-center">
          <v-img v-if="item.thumbnail" :src="`${item.thumbnail}`" width="32px"></v-img>
        </div>
      </template>
      <template v-slot:item._id="{ item }">
        <v-chip
          router
          :to="{ name: 'Offers', params: { _offer: item._id } }"
          small
          transparent
          class="font-weight-regular transparent"
          @click="copyToClipboard(`${item._id}`)"
        >
          <v-icon class="copy">{{ Icons.copyContent }}</v-icon>
          <FormattedText :value="item._id" class="transparent" />
        </v-chip>
      </template>
      <template v-slot:item.name="{ item }">
        <FormattedText :value="item.name" />
      </template>
      <template v-slot:item.categories="{ item }">
        <p class="categories"><FormattedText :value="categoriesCharge(item)" /></p>
      </template>
      <template v-slot:item.payout="{ item }">
        <v-chip small label class="font-weight-bold payoutChip">{{
          item.payout | payoutField(item.currency)
        }}</v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip small label :style="colorStatus(item.status)" class="font-weight-bold">{{
          statusText(item.status)
        }}</v-chip>
      </template>
      <template v-slot:item.details="{ item }">
        <v-chip
          @click="select"
          :id="item._id"
          class="ma-2 text-capitalize"
          depressed
          small
          label
          color="white lighten-1"
          textColor="blue"
          style="border: 1px solid #2196f3 !important"
          >Details <v-icon right>mdi-chevron-right</v-icon></v-chip
        >
      </template>
    </data-table>
  </v-row>
</template>
<script>
import { inject, onBeforeMount, onMounted } from '@vue/composition-api';
import FormattedText from '../../Components/FormattedText';
import DataTable from '../../Components/DataTable';

export default {
  name: 'offer-preview',
  components: {
    FormattedText,
    'data-table': DataTable,
  },
  data() {
    return {
      headers: [
        { text: ' ', align: 'start', value: 'thumbnail', sortable: false },
        { text: 'ID', value: '_id' },
        { text: 'NAME', value: 'name' },
        { text: 'CATEGORIES', value: 'categories', sortable: false },
        { text: 'PAYOUT', value: 'payout' },
        { text: 'STATUS', value: 'status' },
        { text: 'DETAILS', value: 'details', sortable: false },
      ],
      itemStatusUiDetails: {
        ACTIVE: {
          colorStyle: 'backgroundColor:#cdeecc; color:#00aa00;',
          text: 'Approved',
        },
        PAUSED: {
          colorStyle: 'backgroundColor:#ecd8cd; color:#ab653c;',
          text: 'Rejected',
        },
        EXPIRED: {
          colorStyle: 'backgroundColor:#d2e8f3; color:#ffde9a;',
          text: 'Applied',
        },
        DELETED: {
          colorStyle: 'backgroundColor:#ffcccc; color:#ff0000;',
          text: 'Blocked',
        },
        STANDBY: {
          colorStyle: 'backgroundColor:#f4d0ff; color:#1f8bc4;',
          text: 'Apply to Run',
        },
        default: {
          colorStyle: 'backgroundColor:#cccccc; color:#000000;',
          text: 'Status',
        },
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const select = () => context.emit('select');
    const Offers = inject('Offers');
    const Categories = inject('Categories');
    onBeforeMount(() => {
      Categories.actions.loadItems();
    });
    const copyToClipboard = (text) => {
      if (navigator.clipboard) navigator.clipboard.writeText(text);
    };
    return { select, Offers, Categories, copyToClipboard };
  },
  methods: {
    categoriesCharge(item) {
      const categories = this.Categories.state.items;
      let arrayCategories = [];

      for (let idNum = 0; idNum < item._categories.length; idNum++) {
        let valCate = categories.find((cat) => cat._id == item._categories[idNum]);
        if (valCate) {
          arrayCategories.push(valCate.name);
        }
      }
      return arrayCategories.join(' / ');
    },
    colorStatus(status) {
      return (
        this.itemStatusUiDetails[status]?.colorStyle || this.itemStatusUiDetails.default.colorStyle
      );
    },
    statusText(status) {
      return this.itemStatusUiDetails[status]?.text || this.itemStatusUiDetails.default.text;
    },
  },
};
</script>
<style lang="scss">
.v-avatar {
  border-radius: 0 !important;
}
.on-hover {
  border-width: 2px !important;
  border-color: #0088ee !important;
}
.payoutChip {
  background-color: #ffaa00 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 5px 15px !important;
  text-align: center !important;
  justify-self: center !important;
}
.categories {
  margin: auto !important;
}
.copy {
  width: 18px;
  height: 18px;
  margin: auto;
}
</style>
